<template>
  <div>
    <van-nav-bar
      title="血糖模型"
    />
    <div class="caculate_centent" v-loading="loading" element-loading-text="血糖模型获取中……">
      <div class="rectangle one" @click="gotoDetail">
        <!-- <span class="text-top">当前积分</span> -->
        <span class="text-top">血糖模型完整度</span>
        <span class="text-score">{{currScore}}</span>
      </div>
      <div class="rectangle">
        <span class="rectangle-title">血糖模型积分比例</span>
        <div id="main" class="chart"></div>
      </div>
      <div class="rectangle desc_link">日常录入健康数据，可增加积分，<span class="app_link" @click="gotoApp">点这里录入-></span></div>
      <!-- <div class="rectangle progress" v-if="simPercent >= 100">
        <span class="rectangle-title">血糖模拟解锁进度</span>
        <div class="progress-area">
          <el-button type="primary" size="medium" @click="gotoMimic()">已解锁</el-button>
          <div v-if="simPercent < 100">积分达到60分可解锁血糖模拟功能哟~</div>
          <div v-else>您已经解锁血糖模拟功能啦，点击上方按钮可进入该模块~</div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { NavBar } from 'vant';
import 'vant/lib/index.css';
import * as echarts from 'echarts';

Vue.use(NavBar);

export default {
  name: "GluModel",
  data() {
    return {
      loading: true,
      userUuid: "",
      dataObj: {},
      simPercent: 0,
      progressColor: "#3a71fe",
      currScore: 0
    }
  },
  mounted() {
    this.userUuid = this.$route.query.userUuid;
    this.getData();
  },
  methods: {
    gotoApp() {
      // this.$message.warning('暂未完成，敬请期待。');
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android安卓
      // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios苹果

      if (isAndroid) {
        // window.WebViewJavascriptBridge.callHandler('gotoAddRecord', null, function (response) {
				// 	console.log(response);
				// });

        window.injectedObject.gotoAddRecord();
      }
    },
    gotoMimic() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android安卓
      // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios苹果
      if (isAndroid) {
        window.injectedObject.gotoSimulation();
      }
    },
    gotoDetail() {
      this.$router.push({
				path: '/glu/scoreDetail',
				query: {
					userUuid: this.userUuid
				}
			});
    },
    getData() {
      if(this.userUuid != null && this.userUuid != "") {
          this.$axios({
						method: "post",
						url: "/gluApp/glu/app/glu/goal/gluModel", // 接口地址
						data: {
              userUuid: this.userUuid
            }
					}).then(res => {
            this.loading = false;
            if(res.data.code === 200) {
              this.dataObj = res.data.data;
              // 当前积分，超过100分暂时只显示100
              if (this.dataObj.totalScore > 100) {
                this.currScore = 100;
              } else {
                this.currScore = this.dataObj.totalScore;
              }
              this.simPercent = Math.floor(this.dataObj.totalScore / 60 * 100);
              if (this.simPercent > 100) {
                this.simPercent = 100;
              }
              // 分太少了，乘10先试一下
              // this.simPercent = Math.floor(this.dataObj.totalScore*10 / 60 * 100);
              this.setChart();
            }
          });
      }
    },
    setChart() {
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: ''
        },
        radar: {
          // shape: 'circle',
          zlevel: 10,
          z: 10,
          center: ['50%', '50%'],
          radius: 98,
          indicator: [
            { name: '日常登录(10分)', max: 10 },
            { name: '基础资料\n(10分)', max: 10 },
            { name: '健康数据录入\n(40分)', max: 40 },
            { name: '设备连接\n(30分)', max: 30 },
            { name: '数据导入\n(40分)', max: 40 }
          ]
        },
        series: [
          {
            name: 'Budget vs spending',
            type: 'radar',
            data: [
              {
                value: [this.dataObj.loginScore, this.dataObj.basicScore, this.dataObj.fillScore, this.dataObj.bindScore, this.dataObj.dataScore],
                name: 'Allocated Budget'
              }
            ]
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
}
</script>
<style scoped>
.caculate_centent {
	background-color: #efefef;
  margin: 0;
  padding: 0;
  /* display: grid;
  grid-template-rows: 0.4fr 1.1fr 0.4fr; 
  height: 120vh; */
  display: flex;
  flex-direction: column;
  /* height: 500px; */
}

.rectangle {
  /* flex: 1; */
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  margin: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  position: relative;
  height: 300px;
}

.one {
  /* background-color: #3a71fe; */
  background-color: #5967FF;
  color: #fff;
  height: 70px;
}

.rectangle .chart {
  height: 99%;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  margin-top: 10px;
}

.text-top {
    position: absolute;
    top: 10px;
    left: 10px;
}

.text-score {
  font-size: 1cm;
  font-weight: bolder;
}

.text-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

.rectangle-title {
    position: absolute;
    top: -30px;
    left: 18%;
    transform: translateX(-50%);
    font-weight: bold;
}

.progress {
  display: block;
  height: 80px;
}

.progress-area {
  margin: 10px;
}

.progress-area div {
  color:#9e9e9e;
  font-size: 12px;
  text-align: left;
  margin-top: 10px;
}

/deep/ .el-progress-bar__inner {
  background-color: #3a71fe;
  border-radius: 5px;
}

/deep/ .el-progress-bar__outer {
  border-radius: 5px;
}
/deep/ .el-progress-bar__innerText {
  font-size: 14px;
  font-weight: bold;
  margin: 0 10px;
}

.van-nav-bar {
  background: #0F276D; 
  position: sticky;
  top: 0; 
  z-index: 99;
}
/deep/ .van-nav-bar__title {
  color: white !important;
  font-size: 18px;
}

/deep/ .van-nav-bar__content {
  height: 45px;
}

.desc_link {
  height: 20px;
  background-color: #efefef;
  border: none;
  margin: -20px 0 20px 15px;
  align-items: flex-start;
  justify-content: flex-start;
  color: #9e9e9e;
  font-size: 14px;
}

.desc_link .app_link {
  /* color: #3a71fe; */
  color: #5967FF;
}

/deep/ .el-button {
  /* background-color: #3a71fe;
  border-color: #3a71fe; */
  background-color: #5967FF;
  border-color: #5967FF;
  width: 100%;
  height: 40px;
}
</style>